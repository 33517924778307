import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>


    <p>{`A serious vulnerability has been found in macOS High Sierra - something I have no clue how could have ever happened. The main problem with this issue is, that it really `}<strong parentName="p">{`doesn't require any computer knowledge at all to take advantage of`}</strong>{`. Your grandparents can do it, given they are able to move the mouse and use the keyboard (most non-techie users I know these days have finally learned this).`}</p>
    <p>{`For some odd reason, the user "root" has `}<strong parentName="p">{`no password`}</strong>{`!!!`}</p>
    <p>{`This means: anyone with access to your computer can login as administrator using no password. The username is `}<strong parentName="p">{`root`}</strong>{` and the password is blank. Blank is in total blank. Not "blank". Yes. That's right. BLANK.`}</p>
    <h2>{`What can a root user do?`}</h2>
    <ul>
      <li parentName="ul">{`Access your other user accounts`}</li>
      <li parentName="ul">{`Disable Local Disk Encryption (FileVault)`}</li>
      <li parentName="ul">{`Disable firewalls`}</li>
      <li parentName="ul">{`Basically, he is `}<strong parentName="li">{`GOD`}</strong>{` on your computer, and can do whatever he wants.`}</li>
    </ul>
    <h2>{`What you need to do`}</h2>
    <p>{`Apple is working on a fix, but there's no time to wait. `}<strong parentName="p">{`Please`}</strong>{` go ahead and change the root password to a strong password. `}<a parentName="p" {...{
        "href": "https://support.apple.com/da-dk/HT204012"
      }}>{`See Apples Guide how to`}</a>{`.`}</p>
    <p>{`Some people advise to disable the root account, `}<strong parentName="p">{`but this is misunderstood`}</strong>{`. If you disable the root account, you can reenable it again using a failed login attempt. Whatever you enter as password in the failed attempt, will now be the new password for the root user.`}</p>
    <p>{`If you're familiar with the terminal, you can simply run this command:`}</p>
    <p>{`cat /dev/urandom | env LC_CTYPE=C tr -dc a-zA-Z0-9 | head -c 60 | xargs -I rootpw sudo dscl . -passwd /Users/root rootpw`}</p>
    <h2>{`This is serious.`}</h2>
    <p>{`It is even possible to remotely access your MacBook if you have this feature enabled, `}<strong parentName="p">{`so go do something about it`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      